<template>
  <div class="licesn_conatiner">
    <p style="font-size: 14pt; line-height: 108%; margin: 0pt 0pt 8pt; text-align: justify">
      <span style="font-family: Calibri; font-size: 14pt; font-weight: bold"
        >END USER LICENSE AGREEMENT</span
      >
    </p>
    <p style="font-size: 14pt; line-height: 108%; margin: 0pt 0pt 8pt; text-align: justify">
      <span style="font-family: Calibri; font-size: 14pt; font-weight: bold"
        >FOR LINUX EMULATOR</span
      >
    </p>
    <p style="font-size: 11pt; line-height: 108%; margin: 0pt 0pt 8pt; text-align: justify">
      <span style="font-family: Calibri; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 108%; margin: 0pt 0pt 8pt; text-align: justify">
      <span style="font-family: Calibri; font-size: 11pt; font-weight: bold"
        >IMPORTANT – READ THIS AGREEMENT BEFORE DOWNLOADING, INSTALLING, OR USING THE
        SOFTWARE.&nbsp; BY DOWNLOADING, INSTALLING OR USING THE SOFTWARE, YOU REPRESENT THAT YOU
        PURCHASED THE SOFTWARE FROM AN APPROVED SOURCE AND YOU AGREE TO BE BOUND BY THE TERMS OF
        THIS AGREEMENT.&nbsp; IF YOU ARE ACCEPTING THESE TERMS ON BEHALF OF ANOTHER PERSON, COMPANY,
        OR OTHER LEGAL ENTITY, YOU REPRESENT AND WARRANT THAT YOU HAVE FULL AUTHORITY TO BIND THAT
        PERSON, COMPANY, OR LEGAL ENTITY TO THESE TERMS.</span
      >
    </p>
    <p style="font-size: 11pt; line-height: 108%; margin: 0pt 0pt 8pt; text-align: justify">
      <span style="font-family: Calibri; font-size: 11pt; font-weight: bold"
        >IF YOU DO NOT AGREE TO THESE TERMS:</span
      >
    </p>
    <ul type="disc" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          font-family: serif;
          font-size: 11pt;
          line-height: 108%;
          margin: 0pt 0pt 0pt 28.06pt;
          padding-left: 7.94pt;
          text-align: justify;
          text-indent: 0pt;
        "
      >
        <span style="font-family: Calibri; font-size: 11pt; font-weight: bold"
          >DO NOT DOWNLOAD, INSTALL, COPY, ACCESS OR USE THE SOFTWARE; AND
        </span>
      </li>
      <li
        style="
          font-family: serif;
          font-size: 11pt;
          line-height: 108%;
          margin: 0pt 0pt 8pt 28.06pt;
          padding-left: 7.94pt;
          text-align: justify;
          text-indent: 0pt;
        "
      >
        <span style="font-family: Calibri; font-size: 11pt; font-weight: bold"
          >PROMPTLY DELETE OR RETURN THE SOFTWARE.</span
        >
      </li>
    </ul>
    <p style="font-size: 11pt; line-height: 108%; margin: 0pt 0pt 8pt; text-align: justify">
      <span style="font-family: Calibri; font-size: 11pt"
        >The Licensor may add to, change or remove any part, term or condition of this agreement,
        includin</span
      ><span style="font-family: Calibri; font-size: 11pt"
        >g but not limited to as it applies to the Linux Emulator (the “Software”) at any time
        without prior notice or liability to you.&nbsp; Any such additions, changes, removals posted
        at </span
      ><a href="https://partner.zeasn.com/partnerportal/"
        ><span
          style="color: #0000ff; font-family: Calibri; font-size: 11pt; text-decoration: underline"
          >https://partner.zeasn.com/partnerportal/</span
        ></a
      ><span style="font-family: Calibri; font-size: 11pt"> </span
      ><span style="font-family: Calibri; font-size: 11pt"
        >shall apply as soon as posted.&nbsp; By continuing to use the Product or Software after so
        posted, you are indicating your acceptance thereto.</span
      >
    </p>
    <p style="font-size: 11pt; line-height: 108%; margin: 0pt 0pt 8pt; text-align: justify">
      <span style="font-family: Calibri; font-size: 11pt"
        >This End-User License Agreement (the “Agreement”) is a legal agreement hereby entered into
        between you, either an individual, company or other legal entity, and its affiliates (the
        “Customer”) and </span
      ><span style="font-family: Calibri; font-size: 11pt"
        >Beijing ZEASN Information Technology Co., Ltd.</span
      ><span style="font-family: Calibri; font-size: 11pt"> (the “L</span
      ><span style="font-family: Calibri; font-size: 11pt">icensor”) of the Software.</span>
    </p>
    <p style="font-size: 11pt; line-height: 108%; margin: 0pt 0pt 8pt; text-align: justify">
      <span style="font-family: Calibri; font-size: 11pt">&nbsp;</span>
    </p>

    <ul style="padding-left: 0">
      <li class="title">
        <span class="title_num">1.</span>
        <span> Intellectual Property Rights</span>
      </li>
      <ul>
        <li class="flex_between">
          <span class="title_num">1.1</span>
          <span class="flex1">
            Ownership.Title to the Software, Documentation, Updates and all patents, copyrights,
            trade secrets and other worldwide proprietary and intellectual property rights in or
            related thereto are and will remain the exclusive property of Licensor.&nbsp; Customer
            may not remove any titles, trademarks or trade names, copyright notices, legends or
            other proprietary markings in or on the Software, hardware or Documentation and will not
            acquire any rights in the Software, except the limited license specified in this
            Agreement.</span
          >
        </li>
        <li class="flex_between">
          <span class="title_num">1.2</span>
          <span class="flex1">
            Licensor owns all rights in any copy, translation, modification, adaptation or
            Derivative Works of the Software, including any improvement or development
            thereof.&nbsp; Licensor retains all rights not expressly granted to Customer in this
            Agreement.&nbsp; Customer shall promptly notify Licensor in writing upon discovery of
            any unauthorized use of the Software or Documentation or infringement of Licensor’s
            proprietary rights in the Software or Documentation.
          </span>
        </li>
      </ul>
    </ul>
    <ul style="padding-left: 0">
      <li class="title">
        <span class="title_num">2.</span>
        <span> License Grant</span>
      </li>
      <ul>
        <li class="flex_between">
          <span class="title_num">2.1</span>
          <span class="flex1">
            Customer is granted a non-exclusive, non-transferable, non-assignable, restricted
            license during the term set forth in this Agreement, to access, install, and use one
            production copy and one backup copy of the Software in accordance with the relevant
            Documentation for Customer’s own internal business purposes only.
          </span>
        </li>
        <li class="flex_between">
          <span class="title_num">2.2</span>
          <span class="flex1">
            Customer is not granted rights to Updates and Customer agrees that the form and nature
            of the Software may change without prior notice to the Customer and that future versions
            of the Software may be incompatible with Applications developed on previous versions of
            the Software.
          </span>
        </li>
        <li class="flex_between">
          <span class="title_num">2.3</span>
          <span class="flex1">
            Customer agrees that Licensor may stop (permanently or temporarily) providing the
            Software, or any features within the Software to the Customer or to users generally, at
            Licensor’s sole discretion, without prior notice to the Customer.
          </span>
        </li>
        <li class="flex_between">
          <span class="title_num">2.4</span>
          <span class="flex1">
            Customer acknowledges the Software and Documentation is proprietary to Licensor and may
            not be distributed to third parties.
          </span>
        </li>
        <li class="flex_between">
          <span class="title_num">2.5</span>
          <span class="flex1">
            The license granted herein is subject to the specific restrictions and limitations as
            set forth herein and/or any additional licensing restrictions and limitations specified
            in the Documentation, or by notification and/or policy change posted at Licensor’s
            website. The Customer has the obligation to check Licensor’s web site from time to time
            to review any changes to this License Agreement. Unless otherwise specified, such
            changes shall be effective when they are posted. By using the Software after the
            occurrence of any such changes to this Agreement, the Customer agrees to the changes.
          </span>
        </li>
      </ul>
    </ul>

    <ul style="padding-left: 0">
      <li class="title">
        <span class="title_num">3.</span>
        <span> License Restrictions </span>
      </li>
      <ul>
        <li class="flex_between">
          <span class="title_num">3.1</span>
          <div class="flex1">
            The Customer may not, and may not cause or allow any third party to:
            <div class="flex_between">
              <span class="title_num">(a)</span>
              <span class="flex1">
                Decompile, decrypt, disassemble, or reverse-engineer the Software in whole or in
                part, or create or recreate the source code for the Software;
              </span>
            </div>
            <div class="flex_between">
              <span class="title_num">(b)</span>
              <span class="flex1">
                Make copies, execute, publish, or reproduce Software or Documentation, unless
                expressly authorized in this Agreement ( and all copies must maintain Licensor’s
                copyright notices);
              </span>
            </div>
            <div class="flex_between">
              <span class="title_num">(c)</span>
              <span class="flex1">
                Remove, erase, obscure or tamper with any copyright or any other product
                identification or proprietary rights notices, seal or instructional label printed or
                stamped on, affixed to, or encoded or recorded in or on any Software or
                Documentation; or fail to preserve all copyright and other proprietary notices in
                all copies Licensor makes of the Software and Documentation;
              </span>
            </div>
            <div class="flex_between">
              <span class="title_num">(d)</span>
              <span class="flex1">
                Sell, rent, lease, lend, use collectively, record, market, license, sublicense,
                distribute, publicly communicate, transfer or exploit in any manner or otherwise
                grant to any person or entity any right to use the Software except to the extent
                expressly permitted in this Agreement; or use the Software to provide, alone or in
                combination with any other product or service, any product or service to any person
                or entity, whether on a fee basis or otherwise;
              </span>
            </div>
            <div class="flex_between">
              <span class="title_num">(e)</span>
              <span class="flex1">
                Modify, adapt, tamper with, translate or create Derivative Works based on the
                Software, Documentation or any other Confidential Information of the Licensor;
                combine or merge any part of the Software or Documentation with or into any other
                software or documentation; or refer to or otherwise use the Software as part of any
                effort to develop software (including any routine, script, code or program) having
                any functional tributes, visual expressions or other features similar to those of
                the Software to compete with the Licensor;
              </span>
            </div>
            <div class="flex_between">
              <span class="title_num">(f)</span>
              <span class="flex1">
                Alter or modify the Software without the prior written consent of Licensor;
              </span>
            </div>
            <div class="flex_between">
              <span class="title_num">(g)</span>
              <span class="flex1">
                Reject, avoid, elude, remove, deactivate, or evade, in any way, any protection
                mechanism of the Software, including without limitation any mechanism used to
                restrict or control Software functions;
              </span>
            </div>
            <div class="flex_between">
              <span class="title_num">(h)</span>
              <span class="flex1">
                Except with the Licensor’s prior written permission, publish any performance or
                benchmark tests or analysis relating to the Software or otherwise disclose to any
                third party any benchmarking or comparative study involving the Software or
                Documentation;
              </span>
            </div>
            <div class="flex_between">
              <span class="title_num">(i)</span>
              <span class="flex1">
                Provide or offer access to any third parties to any restricted online access keys or
                authentication passwords provided by Licensor for downloading the Software;
              </span>
            </div>
            <div class="flex_between">
              <span class="title_num">(j)</span>
              <span class="flex1">
                Run or operate the Software in a cloud, internet-based computing or similar
                on-demand computing environment unless the Licensor provides written consent for
                such use.
              </span>
            </div>
            <div class="flex_between">
              <span class="title_num">(k)</span>
              <span class="flex1">
                Use the Software in any manner that is not expressly permitted pursuant to this
                License Agreement or by law;
              </span>
            </div>
            <div class="flex_between">
              <span class="title_num">(l)</span>
              <span class="flex1">
                Use the Software in any way that violates or infringes upon the rights of any third
                party.
              </span>
            </div>
          </div>
        </li>
      </ul>
      <ul>
        <li class="flex_between">
          <span class="title_num">3.2</span>
          <div class="flex1">
            Customer agrees to comply with all applicable laws and regulations. Without limiting the
            foregoing, Customer may not access, download, install, use, export or re-export the
            Software in violation of export laws, regulations or restrictions, or in violation of
            any other applicable laws, regulations or restrictions. Customer agrees to comply with
            all export and import laws, regulations and restrictions of all applicable agencies and
            authorities, and not to directly or indirectly use the Software, or any part thereof, in
            violation of any such laws, regulations or restrictions, or without all necessary
            approvals. Customer also agrees that Customer shall not use the Software for any
            purposes prohibited by law, regulations or restrictions of any government.
          </div>
        </li>
        <li class="flex_between">
          <span class="title_num">3.3</span>
          <div class="flex1">
            In addition, and without limitation of any other provision contained herein, Customer
            shall not do any of the following with the Software:
            <div class="flex_between">
              <span class="title_num">(a)</span>
              <div class="flex1">
                Create or promote any products, services or materials that constitute, promote or
                are used primarily for the purpose of dealing in spyware, adware or other malicious
                programs or code, counterfeit goods, hate materials or materials urging acts of
                terrorism or violence;
              </div>
            </div>
            <div class="flex_between">
              <span class="title_num">(b)</span>
              <div class="flex1">
                Operate nuclear facilities, life support or other mission critical application where
                human life or property may be at stake.
              </div>
            </div>
          </div>
        </li>
      </ul>
    </ul>

    <ul style="padding-left: 0">
      <li class="title flex_between">
        <span class="title_num">4.</span>
        <div class="flex1">
          Technical Support and Maintenance
          <div>
            This Agreement does not entitle the Customer to receive support, telephone assistance,
            enhancements, updates or upgrades.
          </div>
        </div>
      </li>
    </ul>

    <ul style="padding-left: 0">
      <li class="title flex_between">
        <span class="title_num">5.</span>
        <div class="flex1">Data Collection and Privacy</div>
      </li>
      <ul>
        <li class="flex_between">
          <span class="title_num">5.1</span>
          <span class="flex1">
            The Software may employ applications and tools to collect Personal Data or other
            information about the Customer and End Users. Such Personal Data shall be used and
            maintained in accordance with Licensor’s Privacy Policy posted on its website at
            https://partner.zeasn.com/partnerportal/.
          </span>
        </li>
        <li class="flex_between">
          <span class="title_num">5.2</span>
          <span class="flex1">
            By entering into this Agreement, or using the Software, Customer and End Users agree to
            the Licensor’s Privacy Policy available at
            <a href="https://partner.zeasn.com/partnerportal/"
              >https://partner.zeasn.com/partnerportal/
            </a>
            and to the collection, processing, copying, backup, storage, transfer and use of the
            Personal Data collected by the Licensor and it service providers.
          </span>
        </li>
      </ul>
    </ul>

    <ul style="padding-left: 0">
      <li class="title flex_between">
        <span class="title_num">6.</span>
        <div class="flex1">
          Termination
          <div>
            This License Agreement shall remain in effect until terminated by the Customer or
            Licensor as set forth below.
          </div>
        </div>
      </li>
      <ul>
        <li class="flex_between">
          <span class="title_num">6.1</span>
          <span class="flex1">
            Customer may terminate this Agreement at any time by uninstalling the Software.
          </span>
        </li>
        <li class="flex_between">
          <span class="title_num">6.2</span>
          <div class="flex1">
            <div>
              Licensor shall have the right to terminate this Agreement at any time for any reason.
              This Agreement shall automatically terminate if:
            </div>
            <div class="flex_between">
              <span class="title_num">(a)</span>
              <span class="flex1">Customer breaches this Agreement in any manner; </span>
            </div>
            <div class="flex_between">
              <span class="title_num">(b)</span>
              <span class="flex1">
                Licensor publicly posts a written notice of termination on its web site;
              </span>
            </div>
            <div class="flex_between">
              <span class="title_num">(c)</span>
              <span class="flex1"
                >Licensor sends a written notice of termination to the Customer;
              </span>
            </div>
            <div class="flex_between">
              <span class="title_num">(d)</span>
              <span class="flex1"
                >Licensor no longer provides the Software to users in the country to which the
                Customer resides or from which the Customer accesses the Software.</span
              >
            </div>
          </div>
        </li>
        <li class="flex_between">
          <span class="title_num">6.3</span>
          <div class="flex1">
            <div>Upon termination of this Agreement, Customer and Authorized Users shall:</div>
            <div class="flex_between">
              <span class="title_num">(a)</span>
              <span class="flex1"
                >Automatically cease to have any rights with respect to the Software; and</span
              >
            </div>
            <div class="flex_between">
              <span class="title_num">(b)</span>
              <span class="flex1">
                Immediately cease all use of the Software and destroy all copies thereof that are in
                the Customer’s possession or control. All provisions of this Agreement, except for
                the grant of license in section 2 above, shall survive the termination of this
                Agreement.
              </span>
            </div>
          </div>
        </li>
      </ul>
    </ul>

    <ul style="padding-left: 0">
      <li class="title flex_between">
        <span class="title_num">7.</span>
        <div class="flex1">Disclaimer of Warranties</div>
      </li>
      <ul>
        <li class="flex_between">
          <span class="title_num">7.1</span>
          <span class="flex1">
            CUSTOMER EXPRESSLY ACKNOWLEDGES AND AGREES THAT CUSTOMER’S DOWNLOADING, INSTALLATION AND
            USE OF THE SOFTWARE IS AT CUSTOMER’S OWN RISK AND THAT THE ENTIRE RISK AS TO
            SATISFACTORY QUALITY, PERFORMANCE, ACCURACY AND EFFORT IS WITH THE CUSTOMER. TO THE
            MAXIMUM EXTENT PERMITTED BY LAW, THE SOFTWARE AND ANY RELATED SERVICES ARE PROVIDED “AS
            IS”, WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND. LICENSOR HEREBY DISCLAIMS ALL
            WARRANTIES AND CONDITIONS WITH RESPECT TO THE SOFTWARE AND ANY RELATED SERVICES, WHETHER
            EXPRESS, IMPLIED OR STATUTORY, INCLUDING ALL EXPRESS WARRANTIES, ALL IMPLIED WARRANTIES
            OF MERCHANTABILITY, ALL IMPLIED WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE (EVEN IF
            LICENSOR HAS BEEN MADE AWARE OF SUCH PURPOSE), ALL IMPLIED WARRANTIES OF TITLE, AND ALL
            WARRANTIES OF NON-INTERFERENCE, NON-INFRINGEMENT AND ACCURACY, WITHOUT LIMITING THE
            FOREGOING, LICENSOR DOES NOT WARRANT AND SPECIFICALLY DISCLAIMS ANY WARRANTIES THAT THE
            OPERATION OF THE SOFTWARE WILL BE UNINTERRUPTED OR ERROR FREE, OR THAT DEFECTS IN THE
            SOFTWARE OR RELATED SERVICES WILL BE CORRECTED. NO ORAL OR WRITTEN INFORMATION OR ADVICE
            GIVEN BY LICENSOR OR AN AUTHORIZED REPRESENTATIVE SHALL CREATE ANY WARRANTY.
          </span>
        </li>
        <li class="flex_between">
          <span class="title_num">7.2</span>
          <span class="flex1">
            Some sates or jurisdictions do not allow the exclusion of express or implied warranties,
            so the above disclaimer may not apply to Customer. IN THAT EVENT, SUCH EXPRESS OR
            IMPLIED WARRANTIES SHALL BE LIMITED IN DURATION TO THE WARRANTY PERIOD (OR THE MINIMUM
            PERIOD REQUIRED BY APPLICABLE LAW) AND LICENSOR’S LIABILITY SHALL BE LIMITED TO THE
            MINIMUM EXTENT PERMITTED BY APPLICABLE LAW.
          </span>
        </li>
      </ul>
    </ul>

    <ul style="padding-left: 0">
      <li class="title flex_between">
        <span class="title_num">8.</span>
        <div class="flex1">Limitation of Liability</div>
      </li>
      <ul>
        <li class="flex_between">
          <span class="title_num">8.1</span>
          <span class="flex1">
            IN NO EVENT, WHETHER IN TORT, CONTRACT, OR OTHERWISE, SHALL LICENSOR OR ITS AFFILIATES,
            OR THEIR RESPECTIVE DIRECTORS, OFFICERS, MEMBERS, EMPLOYEES, REPRESENTATIVES,
            SUCCESSORS, ASSIGNS OR AGENTS BE LIABLE TO CUSTOMER OR ANY THIRD PARTIES UNDER THIS
            AGREEMENT FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES,
            COSTS, LSOSES OR EXPENSE, (INCLUDING BUT NOT LIMITED TO, LOST PROFITS, LOSS OR
            INTERRUPTION OF USE, LOSS OF DATA, LOSS OF GOODWILL, WORK STOPPAGE, DAMAGE TO NETWORKS,
            EQUIPMENT, OR HARDWARE, OR THE COST OF PROCUREMENT OF SUBSTITUTE GOODS OR TECHNOLOGY.
          </span>
        </li>
        <li class="flex_between">
          <span class="title_num">8.2</span>
          <span class="flex1">
            Regardless of whether the claim for damages is based in contract, tort, or any other
            legal theory, in no event shall Licensor’s aggregate liability to Customer for direct
            damages exceed the original purchase price of the Software, even if Licensor has been
            advised of such damages. The foregoing limitations shall apply to the maximum extent
            permitted applicable law.
          </span>
        </li>
        <li class="flex_between">
          <span class="title_num">8.3</span>
          <span class="flex1">
            THE LIMITATION OF LIABILITY HEREIN IS BASED ON THE FACT THAT END USERS USE THEIR
            COMPUTERS FOR DIFFERENT PURPOSES. ACCORDINGLY, ONLY CUSTOMER CAN IMPLEMENT BACK-UP PLANS
            AND SAFEGUARDS APPROPRIATE TO CUSTOMER’S NEEDS IN THE EVENT AN ERROR IN THE SOFTWARE
            CAUSES COMPUTER PROBLEMS AND RELATED DATA LOSSES. FOR THESE BUSINESS REASONS, CUSTOMER
            AGREES TO THE LIABILITY LIMITATIONS HEREIN.
          </span>
        </li>
      </ul>
    </ul>

    <ul style="padding-left: 0">
      <li class="title flex_between">
        <span class="title_num">9.</span>
        <div class="flex1">Release and Waiver</div>
      </li>
      <ul>
        <li class="flex_between">
          <span class="title_num">9.1</span>
          <span class="flex1">
            To the maximum extent permitted by applicable law, Customer hereby releases and waives
            all claims against Licensor and its Affiliates and each of their respective officers,
            directors, members, employees, agents, information providers, representatives,
            attorneys, partners, successors and assigns of every kind and nature arising from or in
            any way related to Customer or any other Authorized Users’ downloading, installing
            and/or use of the Software.
          </span>
        </li>
        <li class="flex_between">
          <span class="title_num">9.2</span>
          <span class="flex1">
            If Customer is a California resident, Customer waives its rights under California Civil
            Code §1542, which states, “A general release does not extend to claims which the
            creditor does not know or suspect to exist in his favor at the time of executing the
            release, which if known by him must have materially affected his settlement with the
            debtor.”
          </span>
        </li>
        <li class="flex_between">
          <span class="title_num">9.3</span>
          <span class="flex1">
            Customer understands that any fact relating to any matter covered by the foregoing
            release may be found to be other than now believed to be true and Customer accepts and
            assumes the risk of such possible differences in fact. In addition, Customer waives and
            relinquishes any and all similar rights and benefits which Customer may have under any
            other laws, to the fullest extent permitted by Law.
          </span>
        </li>
      </ul>
    </ul>

    <ul style="padding-left: 0">
      <li class="title flex_between">
        <span class="title_num">10.</span>
        <div class="flex1">
          Indemnification
          <div>
            Notwithstanding any other term of this License Agreement or any act or failure to act by
            Licensor, to the maximum extent permitted by law, Customer agrees to and shall
            indemnify, defend and hold harmless Licensor and its Affiliates and each of their
            respective officers, directors, employees, agents, information providers,
            representatives, attorneys, partners, successors and assigns from and against any and
            all claims, actions, suits, proceedings, losses, liabilities, damages, costs and
            expenses (including attorneys' fees) arising out of or related to:
          </div>
        </div>
      </li>
      <ul>
        <li class="flex_between">
          <span class="title_num">(a)</span>
          <span class="flex1">
            any Application Customer or any other Authorized User develops that infringes upon,
            violates or misappropriates the rights of any third party, including, without
            limitation, any copyright, trademark, trade secret, trade dress, patent or other
            intellectual property right of any third party;
          </span>
        </li>
        <li class="flex_between">
          <span class="title_num">(b)</span>
          <span class="flex1"> Customer’s violation of any law, rule or regulation; </span>
        </li>
        <li class="flex_between">
          <span class="title_num">(c)</span>
          <span class="flex1">
            any non-compliance or breach by Customer of this License Agreement.
          </span>
        </li>
      </ul>
    </ul>

    <ul style="padding-left: 0">
      <li class="title flex_between">
        <span class="title_num">11.</span>
        <div class="flex1">Confidentiality and Notifications</div>
      </li>
      <ul>
        <li class="flex_between">
          <span class="title_num">11.1</span>
          <span class="flex1">
            Customer acknowledges and agrees that the Software incorporates confidential and
            proprietary information (“Confidential Information”) developed or acquired by Licensor
            including, but not limited to, technical and non-technical data, formulas, patterns,
            compilations, devices, methods, techniques, drawings and processes related to the
            Software, which constitutes the valuable intellectual property of Licensor.
          </span>
        </li>
        <li class="flex_between">
          <span class="title_num">11.2</span>
          <span class="flex1">
            Customer shall comply with all laws and regulations that apply to use, transmission,
            storage, disclosure or destruction of Confidential Information. Customer agrees to hold
            Licensor’s Confidential Information in the strictest confidence. Confidential
            Information shall not be disclosed by the Customer to anyone except an employee or agent
            who has a need to know, or who is bound by a non-disclosure and confidentiality
            provision at least as restrictive as those set forth in this Agreement. Customer agrees
            to ensure that its employees, agents, representatives, and contractors are advised of
            the confidential nature of the Confidential Information and are precluded from taking
            any action prohibited under this Agreement. Licensor may use any technical information
            that Customer provides to Licensor for any of Licensor’s reasonable business purposes,
            including product support and development. Customer acknowledges that the Software may
            include a monitoring capability that sends anonymous statistics and performance, device
            utilization and network size remotely to Licensor.
          </span>
        </li>
        <li class="flex_between">
          <span class="title_num">11.3</span>
          <span class="flex1">
            Except as explicitly stated in this Agreement, Customer is granted no license or
            conveyance of Licensor’s Confidential Information or any intellectual property rights
            therein. Title to the Licensor’s Confidential Information shall remain solely with the
            Licensor.
          </span>
        </li>
        <li class="flex_between">
          <span class="title_num">11.4</span>
          <span class="flex1">
            It is further understood and agreed that money damages may not be a sufficient remedy
            for any breach of the confidentiality provisions of this Agreement and that Licensor may
            be entitled to equitable relief, including injunction and specific performance, as a
            remedy for such breach. Such remedies may not be deemed exclusive remedies for a breach
            of these provisions but may be deemed in addition to all other remedies available at law
            or in equity.
          </span>
        </li>
      </ul>
    </ul>

    <ul style="padding-left: 0">
      <li class="title flex_between">
        <span class="title_num">12.</span>
        <div class="flex1">General Provisions</div>
      </li>
      <ul>
        <li class="flex_between">
          <span class="title_num">12.1</span>
          <span class="flex1">
            Assignment. Customer shall not have the right to assign this Agreement or any of
            Customer’s rights hereunder, or to delegate any of Customer’s obligations hereunder,
            whether in whole or in part; any assignment or delegation to the contrary shall be
            deemed void from inception. Licensor shall have the right to assign this Agreement and
            any and all of its rights hereunder, and to delegate any and all of its obligations
            hereunder, to any third party.
          </span>
        </li>
        <li class="flex_between">
          <span class="title_num">12.2</span>
          <span class="flex1">
            Taxes. Customer agrees to pay all taxes (including but not limited to sales, use,
            excise, and value-added taxes), tariffs, duties, custom fees, or similar charges imposed
            or levied on the Software Customer licenses, with the exception of taxes on Licensor’s
            net income.
          </span>
        </li>
        <li class="flex_between">
          <span class="title_num">12.3</span>
          <span class="flex1">
            Severability. If any provisions of this Agreement is held illegal or unenforceable by
            any court of competent jurisdiction, such provision shall be deemed severed from the
            remaining provisions of the Agreement and shall not affect or impair the validity or
            enforceability of the remaining provisions of this Agreement.
          </span>
        </li>
        <li class="flex_between">
          <span class="title_num">12.4</span>
          <span class="flex1">
            Governing Law. This Agreement shall be governed by the laws of [Please insert country]
            without regard to its conflict of law principles. This Agreement shall not be governed
            by the United Nations Convention on Contracts for the International Sale of Goods.
          </span>
        </li>
        <li class="flex_between">
          <span class="title_num">12.5</span>
          <span class="flex1">
            Dispute Resolution. All disputes, controversies and disagreements between the parties
            hereto arising out of or related to this License Agreement shall be exclusively
            submitted for, and finally settled by arbitration. The arbitration shall be administered
            by the Singapore International Arbitration Centre (“SIAC”) in accordance with the SIAC
            rules for the time being in force. The arbitration shall be held in Singapore with one
            (1) arbitrator and the language of the arbitration shall be English.
          </span>
        </li>
        <li class="flex_between">
          <span class="title_num">12.6</span>
          <span class="flex1">
            Entire Agreement. This Agreement sets forth the entire understanding and agreement of
            the parties related to the subject matter hereof and supersedes any and all oral and
            written agreements and understandings between the parties related to the subject matter
            hereof.
          </span>
        </li>
        <li class="flex_between">
          <span class="title_num">12.7</span>
          <span class="flex1">
            Entire Agreement. This Agreement sets forth the entire understanding and agreement of
            the parties related to the subject matter hereof and supersedes any and all oral and
            written agreements and understandings between the parties related to the subject matter
            hereof. Waiver. The failure or delay of the Licensor to exercise any right under this
            Agreement shall not be deemed a waiver of any such right and shall not prevent the
            Licensor from demanding full and exact compliance with this Agreement.
          </span>
        </li>
        <li class="flex_between">
          <span class="title_num">12.8</span>
          <span class="flex1">
            Headings. Headings used in this agreement are for the purpose of convenience only and
            shall not have any legal significance in interpreting the provisions hereof.
          </span>
        </li>
      </ul>
    </ul>
  </div>
</template>
<style lang="scss" scoped>
.licesn_conatiner {
  height: 3.7rem;
  overflow: auto;
  padding: 24px;
  .title {
    font-weight: bold;
  }
  .title_num {
    margin-right: 10px;
  }
}
ul {
  list-style-type: none;
  padding-left: 34px;
}
.flex {
  display: flex;
}
.flex1 {
  flex: 1;
}
.flex_between {
  display: flex;
  justify-content: space-between;
}
</style>