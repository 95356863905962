
import { onMounted, onUnmounted, reactive, ref, SetupContext, toRefs, getCurrentInstance } from 'vue'
import iconMixin from '../../mixins/iconMixin'
import { mapKeyListData } from '@/mock/index'
import {
  InterfaceKeymapinfo,
  InterfaceKeymapinfoItem,
  keyListMap as keyListMapUi,
  KeyMapListInterface
} from '@/constant/src/mapKeyListData'
import { setKeyMap, getKeyMapListData, enterKeyMap } from '@/Api/src/emulatorApi'
import { IS_DEV } from '@/config'
import { bindFunctionKey, noop, setKeynameMaxlenth } from '@/utils'
import { EmulatorEvent, SouceEvent } from '@/tools/notification'
import emitter from '@/tools/bus'
interface InterfaceReactiveProp {
  activeIndex: number
  mapKeyList: InterfaceKeymapinfo
}
declare var window: Window & { 
  myApp: any 
};
export default {
  name: 'Mappingkey',
  mixins: [iconMixin],
  mounted() {
    console.log('mapkeys', IS_DEV)
    bindFunctionKey()
    // testApi().then((res) => {
    //   console.log(res)
    // })
  },
  setup(props: object, context: SetupContext) {
    console.log(props, context)
    window.myApp = getCurrentInstance();// console.log(window.myApp.setupState)
    const state = reactive<InterfaceReactiveProp>({
      activeIndex: 0,
      mapKeyList: []
    })
    const eventKeyup = (data: InterfaceKeymapinfoItem) => {
      const list: any = state.mapKeyList
      let saveKbName = data.kb_name;
      if (data.kb_name.substring(0, 4) === 'KEY_') {
        data.kb_name = data.kb_name.substring(4)
      }
      let len = list.length;
      for(let i = 0; i < len; i++) {
        if (list[i].kb_name === saveKbName || ('KEY_' + list[i].kb_name) === saveKbName) {
          Object.assign(state.mapKeyList[i], {
            kb_name: '...'
          });
          break;
        }
      }
      Object.assign(state.mapKeyList[state.activeIndex], {
        kb_name: data.kb_name,
        kb_code: data.kb_code
      })
    }

    const getKeyMapItem = (item: InterfaceKeymapinfoItem) => {
      return keyListMapUi[item['vkey_name']] || {}
    }

    const getKeyList = () => {
      ;(IS_DEV ? Promise.resolve(mapKeyListData) : getKeyMapListData()).then(
        (data: KeyMapListInterface | any) => {
          console.log('getKeyMapListData', data)
          let mapKeyList = data.keymapinfo
          let tmp = mapKeyListData.keymapinfo.map((item: InterfaceKeymapinfoItem) => {
            mapKeyList.forEach((element: InterfaceKeymapinfoItem) => {
              if (item.vkey_name === element.vkey_name) {
                item = element
              }
            })
            return item
          })
          state.mapKeyList = reactive(tmp)
        }
      )
    }

    const saveKeyMap = () => {
      setKeyMap({
        keynumber: 2,
        keymapinfo: state.mapKeyList
      }).then(() => {
        console.log('提交成功')
      })
    }

    const saveKeyMapDefault = () => {
      setKeyMap({ isdefault: true }).then(getKeyList)
    }

    onMounted(() => {
      getKeyList()
      // enterKeyMap({isEnter:true})
      emitter.on(EmulatorEvent.Keyevent, (data: any) => {
        eventKeyup(data)
      })
    })
    onUnmounted(() => {
      enterKeyMap({ isEnter: false })
      emitter.off(EmulatorEvent.Keyevent)
    })
    return {
      state,
      eventKeyup,
      keyListMapUi,
      getKeyMapItem,
      setKeynameMaxlenth,
      saveKeyMap,
      saveKeyMapDefault
    }
  }
}
