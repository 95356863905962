
import { Options, Vue } from 'vue-class-component'
import HelloWorld from '@/components/HelloWorld.vue' // @ is an alias to /src
import SettingModal from './src/components/SettingModal.vue'
import AddBookmarks from './src/components/AddBookmarks.vue'
import Liscense from './src/components/License.vue'
import emitter from '@/tools/bus'
import { emulatorVersion, IS_DEV, fatureAppList } from '@/config'
import { BookItemInterface, deleteBookMark, getBookMark, setActive } from '@/Api/src/emulatorApi'
import { bookList } from '@/mock'
import { bindFunctionKey, noop } from '@/utils'
import { message } from 'ant-design-vue'
// import  "ant-design-vue/lib/modal/style/css";
export interface BookMarkInterface {
  show: boolean
  isEdit: boolean
  editData: Object
}
@Options({
  name: 'home',
  components: {
    SettingModal,
    AddBookmarks,
    Liscense
  }
})
export default class Home extends Vue {
  emulatorVersion: string = emulatorVersion
  systemTitle: string = 'Linux Emulator'
  requestIconShow: boolean = false
  show: boolean = false
  isEdit: boolean = false
  settingVisible: boolean = false
  appLink: string = ''
  bookList: BookItemInterface[] = []
  fatureAppList: BookItemInterface[] = []
  bookMarkData: BookMarkInterface = {
    show: false,
    isEdit: false,
    editData: {}
  }
  isFirstToHome: boolean = false
  isShowLegal: boolean = false
  mounted() {
    bindFunctionKey()
    this.fatureAppList = fatureAppList
    const that = this
    window.addEventListener('beforeunload', (e) => {
      that.setActive(true)
    })
    emitter.on('socketStart', () => {
      this.setActive(false)
      this.getBookMarks()
    })
    this.getBookMarks()
  }
  unmounted() {
    window.removeEventListener('beforeunload', noop)
    emitter.off('socketStart')
  }
  beforeUnmount() {
    console.log('remove home')
  }
  /**
   * setActive
   */
  public setActive(isActive: boolean) {
    setActive({ isActive })
  }
  /**
   * getBookMarks
   */
  public getBookMarks() {
    return (IS_DEV ? Promise.resolve(bookList) : getBookMark()).then((data: any) => {
      this.bookList = data.appInfo.reverse()
    })
  }
  /**
   * addBookmarks
   */
  public addBookmarks() {
    if (this.bookList.length >= 20) {
      return message.warning('The number of bookmarks cannot over 20')
    }
    this.bookMarkData.show = true
    this.bookMarkData.isEdit = false
  }
  /**
   * addSuccess
   */
  public addSuccess() {
    console.log('添加成功')
    this.closeAddmarks();
    this.getBookMarks();
  }
  /**
   * addCancel
   */
  public addCancel() {
    this.closeAddmarks()
  }
  /**
   * close
   */
  public closeAddmarks() {
    this.bookMarkData.show = false
  }

  removeBook(appId: number) {
    console.log('移除书签')
    deleteBookMark(appId).then(this.getBookMarks)
  }
  toEdit(item: BookItemInterface) {
    this.bookMarkData.isEdit = true
    this.bookMarkData.show = true
    this.bookMarkData.editData = item
  }
  /**
   * openSetting
   */
  public openSetting() {
    this.settingVisible = true
  }
  public showLegal() {
    this.isShowLegal = true
  }
  /**
   * settingCancel
   */
  public settingCancel() {
    this.settingVisible = false
  }
  /**
   * settingSubmit
   */
  public settingSubmit() {
    this.settingVisible = false
  }
  /**
   * enterToApp
   */
  public enterToApp(appLink: string) {
    window.location.href = appLink
  }
  /**
    toLinkApp
   */
  public toLinkApp() {
    if (this.appLink) {
      this.enterToApp(this.appLink)
    } else {
      this.requestIconShow = true
    }
    this.clearSearchValue()
  }
  /**
   * clearSearchValue
   */
  public clearSearchValue() {
    this.appLink = ''
  }
}
