<template>
  <a-form ref="formRef" :model="modelRef" :rules="rulesRef" @finish="onSubmit" class="add_book_marks" :label-col="labelCol" labelAlign="left" :wrapper-col="wrapperCol">
    <a-form-item label="App Name" name="appName">
      <a-input
        v-model:value="modelRef.appName"
      />
    </a-form-item>
    <a-form-item label="App Url" name="appUrl">
      <a-input
        v-model:value="modelRef.appUrl"
      />
    </a-form-item>
    <a-form-item label="App Icon">
      <a-upload
        name="avatar"
        list-type="picture-card"
        class="avatar-uploader"
        :show-upload-list="false"
        :before-upload="beforeUpload"
        @change="handleChange"
      >
        <img class="app_icon" v-if="modelRef.appIcon" :src="modelRef.appIcon" alt="avatar" />
        <div v-else>
          <loading-outlined v-if="loading" />
          <plus-outlined v-else />
          <div class="ant-upload-text">Upload</div>
        </div>
      </a-upload>
    </a-form-item>
    <a-form-item :wrapper-col="{ span: 24, offset: 0 }">
      <div class="flex_between footer_btn">
        <a-button type="primary" html-type="submit"> Ok </a-button>
        <a-button style="margin-left: 10px" @click="onCancel"> Cancel </a-button>
      </div>
    </a-form-item>
  </a-form>
 
</template>

<script >
import { reactive, ref, toRaw } from 'vue';
import { PlusCircleOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import { getBase64 } from '@/utils';
import { setBookMark } from '@/Api/src/emulatorApi';
import { message } from 'ant-design-vue';



export default {
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    PlusCircleOutlined,
    PlusOutlined,
    LoadingOutlined
  },
  setup(props, ctx) {
    const { data } = props
    const { emit } = ctx
    const loading = ref(false)
    const formRef = ref();
    let formData = {
      appName: '',
      appUrl: '',
      appIcon: ''
    }
    if (data.isEdit) {
      formData = Object.assign({}, formData, data.editData)
      formData.bModify = true
    }
    let validatePass = async (_rule, value) => {
      if (value === '') {
        return Promise.reject('Please input the password');
      }
      return Promise.resolve();
    }
    const modelRef = reactive(formData)
    const rulesRef = reactive({
      appName: [
        {
          required: true,
          validator: validatePass,
          message: 'Please input app name',
          trigger: 'blur',
        },
      ],
      appUrl: [
        {
          required: true,
          validator: validatePass,
          message: 'Please input app url',
          trigger: 'blur',
        },
      ],
    });
    let submitLock = ref(false);
    const onSubmit = async (values) => {
      if (submitLock.value) {
        return;
      }
      console.log(values);
      console.log(toRaw(modelRef));
      submitLock.value = true;
      setBookMark(toRaw(modelRef)).then(() => {
        submitLock.value = false;
        emit('onSubmit')
      })
    };
    const onCancel = () => {
      formRef.value.resetFields();
      emit('onCancel')
    }
    const handleChange = (info) => {
      console.log('handleChange', info)
      if (info.file.status === 'uploading') {
        getBase64(info.file.originFileObj, imageUrl => {
          console.log(imageUrl)
          modelRef.appIcon = imageUrl;
        })
        return
      }
      if (info.file.status === 'error') {
        loading.value = false;
      }

      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, imageUrl => {
          console.log(imageUrl)
          modelRef.appIcon = imageUrl;
          loading.value = false
        });
      }
    }
    const beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
    };

    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      modelRef,
      onSubmit,
      onCancel,
      handleChange,
      beforeUpload,
      loading,
      formRef,
      rulesRef,
      submitLock
    };
  },
};
</script>

<style   lang="scss" scoped>
.footer_btn {
  flex-flow: row nowrap;
  width: 100%;
}
.footer_btn .ant-btn {
  min-width: 0.85rem;
}
.app_icon {
  width: 104px;
  height: 104px;
  border-radius: 100%;
}
// .add_book_marks :deep .ant-col{
//   display: flex;
//   // align-items: center;
// }
.add_book_marks :deep .ant-form-item-control{
//  width: 100%;
//   display: flex;
//   align-items: center;
margin-top: .07rem;
 
}
</style>