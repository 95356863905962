import { ResultData } from '../rpcTools'
import emitter from '../bus'


export enum ChannelIds {
  Source = 'source',
  Emulator = 'emulator',
  Realrc = 'realrc',
}
export enum SouceEvent {
  SignalChange = 'source.signal.change',
  RealrcKey = 'realrc.key.press',
}
export enum EmulatorEvent {
 Keyevent = 'emulator.key.event',
}


export const channelIds: string[] = [ChannelIds.Source, ChannelIds.Emulator, ChannelIds.Realrc]

class Notification {
  public source(message: ResultData) {
    const { data } = message.data
    // console.log('source', messa
    if (message.method === SouceEvent.SignalChange) {
      emitter.emit(SouceEvent.SignalChange, data)
    }
  }
  public realrc(message: ResultData) {
    console.log('realrc')
    emitter.emit(EmulatorEvent.Keyevent, message.data.data)
  }
  public emulator(message: ResultData) {
    if (message.method === EmulatorEvent.Keyevent) {
      console.log('EmulatorEvent', message.data)
      emitter.emit(EmulatorEvent.Keyevent, message.data)
    }

  }
}

// tslint:disable-next-line:max-classes-per-file
class HandleNotification extends Notification {

  constructor() {
    super()
  }
  public handleMethod(message: ResultData) {
    if (!message.channel) { return }
    this[message.channel](message)
  }
}

export const handleNotificationData = new HandleNotification()

