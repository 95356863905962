<template>
  <svg t="1619004126575" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="579" width=".7em" height=".7em"><path d="M421.720094 15.058824m45.176471 0l68.668235 0q45.176471 0 45.176471 45.17647l0 903.529412q0 45.176471-45.176471 45.17647l-68.668235 0q-45.176471 0-45.176471-45.17647l0-903.529412q0-45.176471 45.176471-45.17647Z" fill="#686868" p-id="580"></path><path d="M753.014212 15.058824m45.17647 0l68.668236 0q45.176471 0 45.17647 45.17647l0 903.529412q0 45.176471-45.17647 45.17647l-68.668236 0q-45.176471 0-45.17647-45.17647l0-903.529412q0-45.176471 45.17647-45.17647Z" fill="#686868" p-id="581"></path><path d="M560.984094 522.496L86.179388 1011.275294A15.058824 15.058824 0 0 1 60.308329 1000.779294V23.220706A15.058824 15.058824 0 0 1 86.164329 12.724706l474.819765 488.779294a15.058824 15.058824 0 0 1 0 20.992z" fill="#686868" p-id="582"></path></svg>
</template>
<script>
const PlayPause = {
  template: `
`
}
export default {
  props: {
    colorType: {
      type: String,
      default: ''
    },
    active:{
      type:Boolean,
      default:false
    }
  },
}
</script>
<style lang="scss" scoped>
.btn_color {
  width: 0.15rem;
  height: 0.05rem;
  background: #fd7373;
  border-radius: 3px;
}
.red {
  background: #fd7373 !important;
}
.purple {
  background: #7378fd !important;
}
.yellow {
  background: #fdbd73 !important;
}
.blue {
  background: #49d7ff !important;
}
.active{
  border: 1px solid  #fff !important;
}
</style>

