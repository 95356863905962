export const mapKeyListData = {
  keynumber: 2,
  keymapinfo: [
    {
      vkey_name: 'KEY_0',
      vkey_code: 0x30,
      kb_name: 'KEY_0',
      kb_code: 11,
      kb_def: 11,
    },
    {
      vkey_name: 'KEY_1',
      vkey_code: 0x31,
      kb_name: 'KEY_1',
      kb_code: 2,
      kb_def: 2,
    },
    {
      vkey_name: 'KEY_2',
      vkey_code: 0x32,
      kb_name: 'KEY_2',
      kb_code: 3,
      kb_def: 3,
    },
    {
      vkey_name: 'KEY_3',
      vkey_code: 0x33,
      kb_name: 'KEY_3',
      kb_code: 4,
      kb_def: 4,
    },
    {
      vkey_name: 'KEY_4',
      vkey_code: 0x34,
      kb_name: 'KEY_4',
      kb_code: 5,
      kb_def: 5,
    },
    {
      vkey_name: 'KEY_5',
      vkey_code: 0x35,
      kb_name: 'KEY_5',
      kb_code: 6,
      kb_def: 6,
    },
    {
      vkey_name: 'KEY_6',
      vkey_code: 0x36,
      kb_name: 'KEY_6',
      kb_code: 7,
      kb_def: 7,
    },
    {
      vkey_name: 'KEY_7',
      vkey_code: 0x37,
      kb_name: 'KEY_7',
      kb_code: 8,
      kb_def: 8,
    },
    {
      vkey_name: 'KEY_8',
      vkey_code: 0x38,
      kb_name: 'KEY_8',
      kb_code: 9,
      kb_def: 9,
    },
    {
      vkey_name: 'KEY_9',
      vkey_code: 0x39,
      kb_name: 'KEY_9',
      kb_code: 10,
      kb_def: 10,
    },
    {
      vkey_name: 'LEFT',
      vkey_code: 0x25,
      kb_name: 'LEFT',
      kb_code: 105,
      kb_def: 105,
    },
    {
      vkey_name: 'UP',
      vkey_code: 0x26,
      kb_name: 'UP',
      kb_code: 103,
      kb_def: 103,
    },
    {
      vkey_name: 'DOWN',
      vkey_code: 0x28,
      kb_name: 'DOWN',
      kb_code: 108,
      kb_def: 108,
    },
    {
      vkey_name: 'RIGHT',
      vkey_code: 0x27,
      kb_name: 'RIGHT',
      kb_code: 106,
      kb_def: 106,
    },
    {
      vkey_name: 'OK',
      vkey_code: 38,
      kb_name: 'KEY_ENTER',
      kb_code: 28,
      kb_def: 28,
    },
    {
      vkey_name: 'BACK',		// cfg page shown label
      vkey_code: 0x08,			// this code is sending to Whale browser.
      kb_name: 'BACKSPACE',		// the emulator UI show this char/string in configure page, and virtual RC
      kb_code: 14,				// this code is get from kernel.
      kb_def: 14,
    },
    // {
    //   vkey_name: 'KEY_PLAY_PAUSE',
    //   vkey_code: 0x19F,
    //   kb_name: 'KEY_PLAY_PAUSE',
    //   kb_code: 207,
    //   kb_def: 207,
    // },
    {
      vkey_name: 'KEY_PLAY',
      vkey_code: 0x19F,
      kb_name: 'KEY_PLAY',
      kb_code: 207,
      kb_def: 207,
    },
    {
      vkey_name: 'KEY_PAUSE',
      vkey_code: 0x19F,
      kb_name: 'KEY_PAUSE',
      kb_code: 208,
      kb_def: 208,
    },
    {
      vkey_name: 'KEY_PRE',
      vkey_code: 38,
      kb_name: 'KEY_PRE',
      kb_code: 0x19c,
      kb_def: 0x19c,
    },
    {
      vkey_name: 'KEY_NEXT',
      vkey_code: 38,
      kb_name: 'KEY_NEXT',
      kb_code: 0x197,
      kb_def: 0x197,
    },
    {
      vkey_name: 'KEY_FR',
      vkey_code: 0x19C,
      kb_name: 'KEY_FR',
      kb_code: 168,
      kb_def: 168,
    },
    {
      vkey_name: 'KEY_FF',
      vkey_code: 0x1A1,
      kb_name: 'KEY_FF',
      kb_code: 208,
      kb_def: 208,
    },
    // {
    //   vkey_name: 'KEY_PAUSE',
    //   vkey_code: 0x13,
    //   kb_name: 'KEY_PAUSE',
    //   kb_code: 119,
    //   kb_def: 119,
    // },
    {
      vkey_name: 'KEY_STOP',
      vkey_code: 0x19D,
      kb_name: 'KEY_STOP',
      kb_code: 128,
      kb_def: 128,
    },
    {
      vkey_name: 'RED',
      vkey_code: 0x193,
      kb_name: 'RED',
      kb_code: 0x18e,
      kb_def: 0x18e,
    },
    {
      vkey_name: 'GREEN',
      vkey_code: 0x194,
      kb_name: 'GREEN',
      kb_code: 0x18f,
      kb_def: 0x18f,
    },
    {
      vkey_name: 'YELLOW',
      vkey_code: 0x195,
      kb_name: 'YELLOW',
      kb_code: 0x190,
      kb_def: 0x190,
    },
    {
      vkey_name: 'BLUE',
      vkey_code: 0x196,
      kb_name: 'BLUE',
      kb_code: 0x191,
      kb_def: 0x191,
    },
  ],
}


export const bookList = {
  appNum: 2,
  appInfo: [
    {
      appName: 'MyApp_1',
      appUrl: 'https://www.baidu.com',
      appIcon: '',		//
      appIdx: 2,			// app order
    },
    {
      appName: 'MyApp_2',
      appUrl: 'url_3',
      appIcon: '',		//
      appIdx: 3,			// app order
    },
    {
      appName: 'MyApp_3',
      appUrl: 'url_2',
      appIcon: '',		//
      appIdx: 4,			// app order
    },
    {
      appName: 'MyApp_3',
      appUrl: 'url_2',
      appIcon: '',		//
      appIdx: 4,			// app order
    },
    {
      appName: 'MyApp_3',
      appUrl: 'url_2',
      appIcon: '',		//
      appIdx: 4,			// app order
    },
  ],
}
