import { ISocket, SocketReadyState, SocketAction } from './socket';
import { parseJSON } from './utils';
import { emitter } from '../../bus';

/**
 * 监听器容器
 *
 * @interface IListenerMap
 * @template T
 */
interface IListenerMap<T> {
  [properName: string]: T;
}

/**
 * 监听器接口定义
 *
 * @interface IListener
 * @template T
 */
interface IListener<T> {
  /**
   * 添加监听器
   *
   * @param {string} name
   * @param {Function} fn
   * @returns {IListener}
   * @memberof IListener
   */
  add(name: string, fn: Function): this;

  /**
   * 移除监听器
   *
   * @param {string} name
   * @param {Function} fn
   * @returns {IListener}
   * @memberof IListener
   */
  remove(name: string, fn?: Function): this;

  /**
   * 获取监听器
   *
   * @param {string} name
   * @returns {T}
   * @memberof IListener
   */
  get(name: string): T;

  /**
   * 执行监听器
   *
   * @param {string} name
   * @param {...any[]} args
   * @returns {this}
   * @memberof IListener
   */
  exec(name: string, ...args: any[]): this;
}

/**
 * 监听器数组Map
 *
 * @class ListenerListStringMap
 * @implements {IListener<Function[]>}
 */
export class ListenerListStringMap implements IListener<Function[]> {

  constructor(private listenerMap: IListenerMap<Function[]> = {}) { }

  public add(name: string, fn: Function) {
    const listeners = (this.listenerMap[name] || (this.listenerMap[name] = []));
    listeners.push(fn);
    return this;
  }

  public remove(name: string, fn?: Function) {
    const listeners = this.listenerMap[name];
    if (listeners) {
      let index = -1;
      if (fn && (index = listeners.indexOf(fn)) > -1) {
        listeners.splice(index, 1);
        if (listeners.length === 0) {
          delete this.listenerMap[name];
        }
      } else {
        delete this.listenerMap[name];
      }
    }
    return this;
  }

  public get(name: string) {
    return this.listenerMap[name];
  }

  public exec(name: string, ...args: any[]) {
    const listeners = this.listenerMap[name];
    if (listeners && listeners.length) {
      listeners.forEach((listener) => {
        listener.apply(null, args);
      });
    }
    return this;
  }
}

/**
 * 监听器map
 *
 * @class ListenerStringMap
 * @implements {IListener<Function>}
 */
export class ListenerStringMap implements IListener<Function> {

  constructor(private listenerMap: IListenerMap<Function> = {}) { }

  public add(name: string, fn: Function) {
    this.listenerMap[name] = fn;
    return this;
  }
  public remove(name: string, fn?: Function) {
    delete this.listenerMap[name];
    return this;
  }

  public get(name: string) {
    return this.listenerMap[name];
  }

  public exec(name: string, ...args: any[]) {
    const listener = this.listenerMap[name];
    if (listener) {
      listener.apply(null, args);
    }
    return this;
  }
}

/**
 * 响应数据类型
 *
 * @export
 * @enum {number}
 */
export enum ResponseType {
  TEXT = 'text',
  JSON = 'json',
}

type SocketVoidFn = (socket: ISocket) => void;
type HeartbeatFn = SocketVoidFn;
type ReadyFn = SocketVoidFn;
type BeforeMessageFn = (message: string, zeasnSocket: ZeasnSocket) => any;
type DispatchMessageFn = (data: any, zeasnSocket: ZeasnSocket) => void;

/**
 * ZeasnSocket配置项
 *
 * @export
 * @interface ZeasnSocketOptions
 */
export interface ZeasnSocketOptions {
  /**
   * 开启debug
   *
   * @type {boolean}
   * @memberof ZeasnSocketOptions
   */
  debug?: boolean;
  /**
   * 重连次数
   *
   * @type {number}
   * @memberof ZeasnSocketOptions
   */
  reconnectionAttempts?: number;
  /**
   * 心跳
   *
   * @type {HeartbeatFn}
   * @memberof ZeasnSocketOptions
   */
  heartbeat?: HeartbeatFn;
  /**
   * 心跳间隔, 豪秒
   *
   * @type {number}
   * @memberof ZeasnSocketOptions
   */
  heartbeatInterval?: number;
  /**
   * socket就绪后执行
   *
   * @type {ReadyFn}
   * @memberof ZeasnSocketOptions
   */
  ready?: ReadyFn;
  /**
   * 返回数据格式
   *
   * @type {string}
   * @memberof ZeasnSocketOptions
   */
  responseType?: ResponseType;
  /**
   * 前置消息处理, 返回的数据将作为后续的消息处理参数
   *
   * @type {BeforeMessageFn}
   * @memberof ZeasnSocketOptions
   */
  beforeMessage?: BeforeMessageFn;

  /**
   * 消息分发
   *
   * @type {DispatchMessageFn}
   * @memberof ZeasnSocketOptions
   */
  dispatchMessage?: DispatchMessageFn;

  /**
   * 消息事件监听器
   *
   * @type {(ListenerStringMap | IListenerMap<Function>)}
   * @memberof ZeasnSocketOptions
   */
  messageListener?: ListenerStringMap | IListenerMap<Function>;
}

const socketActions: SocketAction[] = [SocketAction.OPEN, SocketAction.MESSAGE, SocketAction.ERROR, SocketAction.CLOSE];

export class ZeasnSocket implements ISocket {

  /**
   * 连接地址
   *
   * @type {string}
   * @memberof ZeasnSocket
   */
  public readonly url: string;

  /**
   * 配置项
   *
   * @type {ZeasnSocketOptions}
   * @memberof ZeasnSocket
   */
  public readonly options: ZeasnSocketOptions = {
    debug: false,
    reconnectionAttempts: 1,
    heartbeatInterval: 60000,
    responseType: ResponseType.TEXT,
    beforeMessage: (data, zeasnSocket) => {
      if (data && zeasnSocket.options.responseType === ResponseType.JSON) {
        return parseJSON(data);
      }
      return data;
    },
  };

  /**
   * 重连次数
   *
   * @private
   * @type {number}
   * @memberof ZeasnSocket
   */
  private reconnectionCount: number = 0;

  /**
   * 心跳定时器
   *
   * @private
   * @type {number}
   * @memberof ZeasnSocket
   */
  private heartbeatTimer: number = 0;

  /**
   * websocket实例
   *
   * @protected
   * @type {WebSocket}
   * @memberof ZeasnSocket
   */
  private wsInstance!: WebSocket;

  /**
   * websocket动作监听器
   *
   * @private
   * @type {IListenerStringMap}
   * @memberof ZeasnSocket
   */
  private actionListener: ListenerListStringMap;

  /**
   * websocket消息监听器
   *
   * @private
   * @type {ListenerStringMap}
   * @memberof ZeasnSocket
   */
  private messageListener: ListenerStringMap;

  constructor(urlOrWsInstance: string | WebSocket, options?: ZeasnSocketOptions) {
    if (urlOrWsInstance instanceof WebSocket) {
      this.wsInstance = (urlOrWsInstance as WebSocket);
      this.url = this.wsInstance.url;
    } else {
      this.url = (urlOrWsInstance as string);
    }
    options = this.options = Object.assign({}, this.options, options);

    // 初始化动作监听器
    this.actionListener = new ListenerListStringMap();

    // 初始化消息监听器
    if (options.messageListener instanceof ListenerStringMap) {
      this.messageListener = (options.messageListener as ListenerStringMap);
    } else {
      this.messageListener = new ListenerStringMap();
      if (options.messageListener) {
        const optionsMessageListener = options.messageListener as IListenerMap<Function>;
        Object.keys(optionsMessageListener).forEach((name) => {
          this.messageListener.add(name, optionsMessageListener[name]);
        });
      }
    }

    this.log('url=' + this.url);
    this.log('options=' + JSON.stringify(this.options));
  }

  public connect() {
    if (!this.wsInstance || this.isReadyState(SocketReadyState.CLOSED)) {
      const wsInstance = this.wsInstance = new WebSocket(this.url);

      // 注册动作监听器
      socketActions.forEach((action) => {
        wsInstance.addEventListener(action, (e) => {
          switch (action) {
            case SocketAction.OPEN:
              if (this.options.ready) {
                this.options.ready.call(null, this);
                emitter.emit('socketStart')
              }
              // 连接开启时启动心跳
              this.heartbeat();
              break;
            case SocketAction.CLOSE:
              // 连接关闭时关闭心跳
              this.closeHeartbeat();
              break;
          }
          this.callAction(action, e);
        });
      });
    }
    return this;
  }

  public reconnect() {
    if (this.options.reconnectionAttempts) {
      if (this.reconnectionCount <= this.options.reconnectionAttempts) {
        this.reconnectionCount++;
        this.connect();
        this.log('尝试重新连接: ' + this.reconnectionCount);
      } else {
        this.log('重新连接失败，超出允许重连次数: ' + this.reconnectionCount + ', ' + this.options.reconnectionAttempts);
      }
    }
    return this;
  }

  public send(data: any) {
    if (!this.wsInstance) {
      this.connect();
    }
    console.log('发送数据: ' ,JSON.parse(data));
    // this.log('发送数据: ' + data);
    this.wsInstance.send(data);
    return this;
  }

  public sendObj(data: Object) {
    return this.send(JSON.stringify(data));
  }

  public disconnect() {
    return this.close();
  }

  public close() {
    if (this.wsInstance) {
      this.wsInstance.close();
    }
    this.closeHeartbeat();
    this.log('断开连接');
    return this;
  }

  public isReadyState(readyState: SocketReadyState | SocketReadyState[]) {
    if (!this.wsInstance) {
      return false;
    }
    if (!(readyState instanceof Array)) {
      readyState = [readyState];
    }
    return readyState.indexOf(this.wsInstance.readyState) > -1;
  }

  public addAction(action: SocketAction, listener: Function) {
    this.actionListener.add(action, listener);
    return this;
  }

  public removeAction(action: SocketAction, listener: Function) {
    this.actionListener.remove(action, listener);
    return this;
  }

  /**
   * 获取消息处理监听器
   *
   * @returns {ListenerStringMap}
   * @memberof ZeasnSocket
   */
  public getMessageListener(): ListenerStringMap {
    return this.messageListener;
  }

  /**
   * 调用动作监听器
   *
   * @private
   * @param {SocketAction} action
   * @param {Event} e
   * @returns
   * @memberof ZeasnSocket
   */
  private callAction(action: SocketAction, e: Event) {
    const actionListener = this.actionListener.get(action);
    if (actionListener && actionListener.length) {
      let data = null;
      switch (action) {
        case SocketAction.MESSAGE:
          if (this.options.beforeMessage) {
            data = this.options.beforeMessage((e as MessageEvent).data, this);
          }
          if (this.options.dispatchMessage) {
            this.options.dispatchMessage(data, this);
          }
          break;
      }
      this.actionListener.exec(action, e, data);
    }
    return this;
  }

  /**
   * 日志输出
   *
   * @private
   * @param {string} msg
   * @returns
   * @memberof ZeasnSocket
   */
  private log(msg: string) {
    if (this.options.debug) {
      console.log('ZeasnSocket - ' ,msg);
    }
    return this;
  }

  /**
   * 关闭心跳
   *
   * @private
   * @returns
   * @memberof ZeasnSocket
   */
  private closeHeartbeat() {
    if (this.heartbeatTimer) {
      clearInterval(this.heartbeatTimer);
      this.heartbeatTimer = 0;
    }
    return this;
  }

  /**
   * 开启心跳
   *
   * @private
   * @returns
   * @memberof ZeasnSocket
   */
  private heartbeat() {
    this.closeHeartbeat();
    if (this.options.heartbeat) {
      this.heartbeatTimer = setInterval(() => {
        (this.options.heartbeat as HeartbeatFn).call(null, this);
      }, this.options.heartbeatInterval);
    }
    return this;
  }
}
