export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_PROD = process.env.NODE_ENV === 'production';

const testApi = 'ws://172.16.0.71:28001/jsonrpc';
// const testApi = 'ws://123.207.136.134:9010/ajaxchattest';
const prodApi = 'ws://127.0.0.1:28001/jsonrpc';
export const Api = IS_PROD ? prodApi : testApi;
export const rpcScoketApi = IS_PROD ? prodApi : testApi;
export const emulatorVersion = ''

export const fatureAppList = [
    {
      appName: 'YouTube',
      appUrl: 'https://www.youtube.com/tv?launch=menu',
      appIcon: require('@/assets/imags/featureApp/YouTube.png'),
      appIdx: 0,
    },
    {
      appName: 'FITE TV',
      appUrl: 'https://ott-apps.fite.tv/zeasn/index.html',
      appIcon: require('@/assets/imags/featureApp/fite.png'),
      appIdx: 1,
    },
    {
      appName: 'WhaleLive',
      appUrl: 'https://whalelive.zeasn.tv/whalelive_web/',
      appIcon: require('@/assets/imags/featureApp/WhaleLive.jpg'),
      appIdx: 2,
    },
    {
      appName: 'PLAY IBIZA',
      appUrl: 'https://whalelive.zeasn.tv/whalelive_web/?productId=1002',
      appIcon: require('@/assets/imags/featureApp/PLAY_IBIZA.png'),
      appIdx: 3,
    },
    // {
    //   appName: 'Mini Portal',
    //   appUrl: 'https://miniportal.nettvservices.com/mini.html',
    //   appIcon: require('@/assets/imags/featureApp/Miniportal.png'),
    //   appIdx: 3,
    // },
  ]

