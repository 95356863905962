import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c041be0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "mapping_keys",
  tabindex: "0"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "mapping_keys_item_name flex_center mapping_keys_item_left" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { class: "mapping_keys_item_right flex_center" }
const _hoisted_7 = { class: "customkey" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.state.mapKeyList, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass([{ active: index == $setup.state.activeIndex }, "mapping_keys_item"]),
        onClick: ($event: any) => ($setup.state.activeIndex = index),
        key: index
      }, [
        _createElementVNode("div", _hoisted_3, [
          ($setup.getKeyMapItem(item).imgSrc)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: $setup.getKeyMapItem(item).imgSrc,
                alt: ""
              }, null, 8, _hoisted_4))
            : ($setup.getKeyMapItem(item).iconComponentsName)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent($setup.getKeyMapItem(item).iconComponentsName), {
                  key: 1,
                  active: $setup.state.activeIndex === index,
                  colorType: $setup.getKeyMapItem(item).colorType
                }, null, 8, ["active", "colorType"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString($setup.getKeyMapItem(item)['name']), 1))
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString($setup.setKeynameMaxlenth(item['kb_name'])), 1)
        ])
      ], 10, _hoisted_2))
    }), 128))
  ]))
}