/**
 * socket状态
 */
export enum SocketReadyState {
  CONNECTING = WebSocket.CONNECTING,
  OPEN = WebSocket.OPEN,
  CLOSING = WebSocket.CLOSING,
  CLOSED = WebSocket.CLOSED
}

/**
 * socket事件
 */
export enum SocketAction {
  OPEN = 'open',
  MESSAGE = 'message',
  ERROR = 'error',
  CLOSE = 'close'
}

/**
 * socket接口
 */
export interface ISocket {

  /**
   * 建立连接
   *
   * @returns {this}
   * @memberof ISocket
   */
  connect(): this

  /**
   * 重新连接
   *
   * @returns {ISocket}
   * @memberof ISocket
   */
  reconnect(): this

  /**
   * 发送数据
   *
   * @param {*} data 发送的数据
   * @returns {ISocket}
   * @memberof ISocket
   */
  send(data: any): this

  /**
   * 发送对象数据
   *
   * @param {Object} data
   * @returns {ISocket}
   * @memberof ISocket
   */
  sendObj(data: Object): this

  /**
   * 断开连接
   *
   * @returns {ISocket}
   * @memberof ISocket
   */
  disconnect(): this

  /**
   * 关闭连接
   *
   * @returns {ISocket}
   * @memberof ISocket
   */
  close(): this

  /**
   * 是否为xx连接状态
   *
   * @param {(SocketReadyState | SocketReadyState[])} readyState 连接状态
   * @returns {boolean}
   * @memberof ISocket
   */
  isReadyState(readyState: SocketReadyState | SocketReadyState[]): boolean

  /**
   * 监听websocket动作
   *
   * @param {SocketAction} action websocket动作
   * @param {Function} listener 监听器
   * @returns {ISocket}
   * @memberof ISocket
   */
  addAction(action: SocketAction, listener: Function): this

  /**
   * 移除websocket动作监听
   *
   * @param {SocketAction} action websocket动作
   * @param {Function} listener 监听器
   * @returns {ISocket}
   * @memberof ISocket
   */
  removeAction(action: SocketAction, listener: Function): this
}