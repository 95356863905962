
export interface IntefaceMapKeyList {
  [index: number]: {
    name: string | number
    value?: string | number
    imgSrc?: string
    iconComponentsName?: string
    colorType?: string,
  };
}
export interface InterfaceKeymapinfoItem {
  'vkey_name': string,		// cfg page shown label
  'vkey_code': number,			// this code is sending to Whale browser.
  'kb_name': string,		// the emulator UI show this char/string in configure page, and virtual RC
  'kb_code': number,				// this code is get from kernel.
  'kb_def': number,
}
export interface InterfaceKeymapinfo {
  [index: number]: InterfaceKeymapinfoItem
}
export interface KeyMapListInterface {
  keynumber?: number
  keymapinfo?: InterfaceKeymapinfo
  isdefault?: boolean
}

export const keyListMap: any = {
  KEY_0: {
    name: 0,
    value: 0,
  },
  KEY_1: {
    name: 1,
    value: 1,
  },
  KEY_2: {
    name: 2,
    value: 2,
  },
  KEY_3: {
    name: 3,
    value: 3,
  },
  KEY_4: {
    name: 4,
    value: 4,
  },
  KEY_5: {
    name: 5,
    value: 5,
  },
  KEY_6: {
    name: 6,
    value: 6,
  },
  KEY_7: {
    name: 7,
    value: 7,
  },
  KEY_8: {
    name: 8,
    value: 8,
  },
  KEY_9: {
    name: 9,
    value: 9,
  },
  UP: {
    name: 'up',
    value: 'Up',
    iconComponentsName: 'UpOutlined',
  },
  DOWN: {
    name: 'down',
    value: 'Down',
    iconComponentsName: 'DownOutlined',
  },
  LEFT: {
    name: 'left',
    value: 'Left',
    iconComponentsName: 'LeftOutlined',
  },
  RIGHT: {
    name: 'right',
    value: 'Right',
    iconComponentsName: 'RightOutlined',
  },
  OK: {
    name: 'ok',
    value: 'Enter',
  },
  BACK: {
    name: 'back',
    value: 'Backsp',
    iconComponentsName: 'RollbackOutlined',
  },
  KEY_PLAY: {
    name: 'play',
    value: '',
    iconComponentsName: 'CaretRightOutlined',
  },
  KEY_PLAY_PAUSE: {
    name: 'playPause',
    value: '',
    iconComponentsName: 'playPause',
  },
  KEY_PRE: {
    name: 'StepBackwardOutlined',
    value: '',
    iconComponentsName: 'StepBackwardOutlined',
  },
  KEY_NEXT: {
    name: 'StepForwardOutlined',
    value: '',
    iconComponentsName: 'StepForwardOutlined',
  },
  KEY_FR: {
    name: 'FastBackwardOutlined',
    value: '',
    iconComponentsName: 'FastBackwardOutlined',
  },
  KEY_FF: {
    name: 'FastForwardOutlined',
    value: '',
    iconComponentsName: 'FastForwardOutlined',
  },
  KEY_PAUSE: {
    name: 'PauseOutlined',
    value: '',
    iconComponentsName: 'PauseOutlined',
  },
  KEY_STOP: {
    name: 'Record',
    value: '',
    iconComponentsName: 'Record',
  },
  RED: {
    name: 'Colors',
    value: '',
    colorType: 'red',
    iconComponentsName: 'Colors',
  },
  GREEN: {
    name: 'Colors',
    value: '',
    colorType: 'purple',
    iconComponentsName: 'Colors',
  },
  YELLOW: {
    name: 'Colors',
    value: '',
    colorType: 'yellow',
    iconComponentsName: 'Colors',
  },
  BLUE: {
    name: 'Colors',
    value: '',
    colorType: 'blue',
    iconComponentsName: 'Colors',
  },
}

