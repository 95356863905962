import {
  RollbackOutlined,
  CaretRightOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
  FastForwardOutlined,
  FastBackwardOutlined,
  PauseOutlined,
  LeftOutlined,
  UpOutlined,
  DownOutlined,
  RightOutlined,
} from '@ant-design/icons-vue';
import Record from '../components/record/index.vue';
import Colors from '../components/colors/index.vue';
import playPause from '../components/playPause/index.vue';
export default {
  components: {
    RollbackOutlined,
    CaretRightOutlined,
    StepForwardOutlined,
    StepBackwardOutlined,
    FastForwardOutlined,
    FastBackwardOutlined,
    PauseOutlined,
    LeftOutlined,
    UpOutlined,
    DownOutlined,
    RightOutlined,
    Record,
    Colors,
    playPause,
  },
};
