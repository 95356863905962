import { channelIds, handleNotificationData, ChannelIds } from './../notification/index';
import { ZeasnSocket, ResponseType } from '../socket/model/zeasnSocket';
import { rpcScoketApi } from '@/config';
import { SocketAction } from '../socket/model/socket';
import { startNotificationSubscribe } from '@/Api/src/emulatorApi';
export interface RequestParams {
  id: string,
  jsonrpc: string,
  method: string,
  params: object
}
export interface InterfaceData {
  data?: object
}
export interface ResultData {
  id: string,
  jsonrpc: string,
  method: string,
  data: InterfaceData,
  result: number
  error?: string
  channel?: ChannelIds
}
export interface ResultObject {
  result?: Function,
  complete?: Function,
  error?: Function
}
export interface ZnAxios {
  method: string,
  params?: object,
  mockData?: object
}
export function makeRpcRequest(method: string, params: object = {}) {
  return {
    jsonrpc: '2.0',
    id: createUUID(),
    method,
    params,
  };
}

export function createUUID() {
  let d = Date.now();
  if (
    typeof performance !== 'undefined' &&
    typeof performance.now === 'function'
  ) {
    d += performance.now();
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
    c,
  ) {
    // tslint:disable-next-line:no-bitwise
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    // tslint:disable-next-line:no-bitwise
    return (c === 'x' ? r : (r & 0x03) | 0x08).toString(16);
  });
}

const callbackMap: Map<string, ResultObject> = new Map()

export let rpcSocket: ZeasnSocket
export function initSocket() {
   rpcSocket = new ZeasnSocket(rpcScoketApi, {
    debug: true,
    heartbeatInterval: 60000,
    reconnectionAttempts: 10,
    responseType: ResponseType.JSON,
    heartbeat: (instance) => {
      instance.sendObj({
        eventType: 'heartBeat',
      })
    },
    ready() {
      console.log('ws ready')
    },
  })
   rpcSocket
    .addAction(SocketAction.OPEN, (e: any) => {
      console.log('open', e)
      startSubsrible()
    })
    .addAction(SocketAction.MESSAGE, (e: any, data: ResultData) => {
      // console.log('onMessage', data)
      messageSocketAction(data)
    })
    .addAction(SocketAction.ERROR, (e: any) => {
      console.log('error', e)
    })
    .addAction(SocketAction.CLOSE, (e: any) => {
      rpcSocket.reconnect()
      console.log('close', e)
    })
   rpcSocket.connect()

  /**
   * messageSocketAction
   */
   function messageSocketAction(message: ResultData) {
    console.log('Response:', message)
    if (message.hasOwnProperty('id') && message.id !== '') {
      handleResult(message)
    } else if (message.hasOwnProperty('channel')) {
      handleNotification(message)
    }
  }

  /**
   * startSubsrible
   * 开启订阅模块
   */
   function startSubsrible() {
    return startNotificationSubscribe({channelIds})
  }

  /**
   *
   * @param message
   */
   function handleResult(message: ResultData) {
    const requestId = message.id
    const cb: any = callbackMap.get(requestId)
    if (message.result === 0) {
      if (cb.hasOwnProperty('result')) {
        let result: object | number = message.data
        if (result === null) {
          result = {}
        }
        cb.result(result)
      }
    } else {
      if (cb.hasOwnProperty('error')) {
        if (message.hasOwnProperty('error')) {
          cb.error(message.error)
        } else {
          cb.error(message.data)
        }
      }
    }
   }

   function handleNotification(message: ResultData) {
     handleNotificationData.handleMethod(message)
  }

   return rpcSocket
}
  /**
   *
   * @param ZnAxios
   */
  // tslint:disable-next-line:no-shadowed-variable
  // tslint:disable-next-line: align
  export function ZnAxios(znAxios: ZnAxios) {
    const { method, mockData, params } = znAxios
    return new Promise((resolve, reject) => {
      const successCallBack = (res: object) => {
        resolve(res)
      }
      const disconnectCallback = () => {
        const handelTimer = setTimeout(() => {
          resolve(mockData)
          clearTimeout(handelTimer)
        }, 500)
      }
      const failCallback = (error: object) => {
        reject(error)
      }
      const requestId = createUUID()
      const requestParams: RequestParams = {
        jsonrpc: '2.0',
        id: requestId,
        method,
        params: params || {},
      }
      const cb: ResultObject = {}
      if (successCallBack) { cb.result = successCallBack }
      if (disconnectCallback) { cb.complete = disconnectCallback }
      if (failCallback) { cb.error = failCallback }
      callbackMap.set(requestId, cb)
      rpcSocket.sendObj(requestParams)
    })
  }
// /**
//  * testSoket
//  */
// function testSoket() {
//   // rpcSocket.sendObj(makeRpcRequest(rpcName))
//   ZnAxios({
//     method: rpcName
//   }).then(res => {
//     console.log('testSoket=>>>', res)
//   })
// }

