import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/home/index.vue';

const routes: RouteRecordRaw[] = [
  {
    redirect: '/home',
    path: '/',
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/keyControlPage',
    name: 'KeyControl',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/keyControl/index.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
