import { message } from 'ant-design-vue';

/**
 * 按键key收到的名字 是 KEY_1 截取 Key_
 * @param name
 */
export function setKeynameMaxlenth(name: string | number = '') {
  name = name.toString()
  if (name.substring(0, 4) === 'KEY_') {
    name = name.substring(4)
  }
  return name.toString().substring(0, 6)
}

// tslint:disable-next-line:no-empty
export function noop() {}

export function getBase64(img: Blob, callback: Function) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export function isValidIP(ip: string) {
  const reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
  return reg.test(ip);
}

export function bindFunctionKey() {
  document.onkeydown = document.onkeypress = (event) => {
    if (event.code.indexOf('F') === 0) {
      if (event.code === 'F1') {
        message.destroy()
        message.warn('Please do not bind the function key of F1.')
      }
      event.preventDefault()
    }
    event.stopPropagation()
  }
}
