import { ZnAxios } from '@/tools/rpcTools';
import { KeyMapListInterface } from '@/constant/src/mapKeyListData';

interface SubsribleInterface {
  channelIds: string[],
}

interface EnterKeyMapInterface {
  isEnter: boolean
}
interface InterfaceSetActive {
  isActive: boolean
}

export interface BookItemInterface {
  'appName': string
  'appUrl': string
  'appIcon'?: string		//
  'appIdx'?: number		// app order
}
/**
 *
 * @param params
 */
export function startNotificationSubscribe(params: SubsribleInterface) {
  return ZnAxios({
    method: 'notification.subscribe',
    params,
  })
}
/**
 *
 * @param params
 */
export function setActive(params: InterfaceSetActive) {
  return ZnAxios({
    method: 'emulator.set.virtualRc.active',
    params,
  })
}



/**
 * 获取键盘列表
 */
export function getKeyMapListData() {
  return ZnAxios({
    method: 'emulator.get.keymap',
  })
}

/**
 * setKeyMap
 * @param params
 */
export function setKeyMap(params: KeyMapListInterface) {
  return ZnAxios({
    method: 'emulator.set.keymap',
    params,
  })
}
/**
 * enterKeyMap
 * 进到 Mapping key 页面开始执行
 * params{isEnter:true} // 离开keymap 也要设置 isEnter=false
 *
 */
export function enterKeyMap(params: EnterKeyMapInterface) {
  return ZnAxios({
    method: 'emulator.doing.keymap',
    params,
  })
}



/*
bookmark 模块
*/
export function getBookMark() {
  return ZnAxios({
    method: 'emulator.get.bookmark',
  })
}
export function setBookMark(params: BookItemInterface) {
  return ZnAxios({
    method: 'emulator.set.bookmark',
    params,
  })
}
export function deleteBookMark(appId: number) {
  return ZnAxios({
    method: 'emulator.delete.bookmark',
    params: {appId},
  })
}


/*
  setting模块
  */
export function getSettingConfig() {
  return ZnAxios({
    method: 'emulator.get.commonsetting',
  })
}
export function setSettingConfig(params: any) {
  return ZnAxios({
    method: 'emulator.set.commonsetting',
    params,
  })
}
