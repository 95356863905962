import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/css';
import '@/assets/js/base';
import { initSocket } from './tools/rpcTools';
import { Button, Modal, Tabs, Radio, Select, Form, Input, Switch, Menu, Dropdown, Divider, Upload, Tooltip } from 'ant-design-vue';

const app = createApp(App).use(store).use(router)
const componentsList = [Button, Modal, Tabs, Radio, Select, Form,
                        Input, Switch, Menu, Dropdown, Divider, Upload, Tooltip]
componentsList.forEach((item) => {
  app.use(item)
})
// app.config.errorHandler = (err, vm, info) => {
//   // tslint:disable-next-line:no-console
//   // tslint:disable-next-line:no-console
//   console.warn('errorStart ' +  err)
// }
app.mount('#app');
initSocket();
