/**
 * 字符串转json
 *
 * @export
 * @param {string} data
 * @returns
 */
export function parseJSON(data: string) {
  if (data) {
    try {
      return JSON.parse(data)
    } catch {
      return new Function('return ' + data)
    }
  }
  return data
}
