<template>
  <div class="btn_color" :class="[colorType,{active:active}]"></div>
</template>
<script>
export default {
  props: {
    colorType: {
      type: String,
      default: ''
    },
    active:{
      type:Boolean,
      default:false
    }
  }
}
</script>
<style lang="scss" scoped>
.btn_color {
  width: 0.15rem;
  height: 0.05rem;
  background: #fd7373;
  border-radius: 3px;
}
.red {
  background: #ff5858 !important;
}
.purple {
  background: #49c76e !important;
}
.yellow {
  background: #fdca73 !important;
}
.blue {
  background: #498bff !important;
}
.active{
  border: 1px solid  #fff !important;
}
</style>

