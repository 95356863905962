<template>
  <div class="z_record_icon" :class="{ active }"></div>
</template>
<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped>
.z_recoßd_icon {
  width: 0.11rem;
  height: 0.11rem;
  background: #686868;
  border-radius: 2px;
  display: inline-block;
}
.active {
  background: #fff !important;
}
</style>