
import { defineComponent } from 'vue'
import { PlusCircleOutlined } from '@ant-design/icons-vue'
import Mappingkey from '@/views/keyControl/src/components/mappingKeys/index.vue'
import { enterKeyMap, getSettingConfig, setSettingConfig } from '@/Api/src/emulatorApi'
import { isValidIP } from '@/utils'
export default defineComponent({
  name:'Setting',
  components: {
    PlusCircleOutlined,
    Mappingkey
  },
  data() {
    const validateIp = (rule: any, value: string, callback: Function) => {
      if (!isValidIP(value)) {
        // callback(new Error('Please input the correct IP'))
        return Promise.reject('Please input the correct IP')
      } else {
        return Promise.resolve()
      }
    }
    const validatePort = (rule: any, value: string, callback: Function) => {
      if (!/^[0-9]*$/.test(value) || value ==='') {
        return Promise.reject('Please input the correct Port')

      } else {
        return Promise.resolve()
      }
    }
    return {
      activeKey: '1',
      switchValue: false,
      settingData: {
        agent: 1,
        resolution: '1920X1080',
        proxy: 0,
        ip: '',
        port: '',
        userurl: '',
        userAgentDefault: ''
      },
      userurl:'',
      rules: {
        ip: [
          // {
          //   required: true,
          //   message: 'Please input Ip',
          //   trigger: 'blur'
          // },
          {
            message: 'Please input the correct IP',
            trigger: 'blur',
            validator: validateIp,
            required: true,
          }
        ],
        port: [
          // {
          //   required: true,
          //   message: 'Please input Port',
          //   trigger: 'blur'
          // },
          {
            required: true,
            message: 'Please input the correct Port',
            trigger: 'blur',
            validator: validatePort
          }
        ]
      },
      labelCol: { span: 5 },
      wrapperCol: { span: 16 },
      userAgentEditorShow: false,
      defaultEditorData: '',
      editorData: '',
      isDisabled: true
    }
  },
  emits: ['onSubmit', 'onCancel'],
  watch: {
    switchValue(n) {
      this.settingData.proxy = n ? 1 : 0
      this.isDisabled = n ? false : true
    },
    activeKey(n) {
      enterKeyMap({ isEnter: n === '4' })
    },
    settingData:{
      handler(n){
      if(n.port == -1){
        this.settingData.port = ''
      }
    },
    deep:true
    }
  },
  created() {
    this.defaultEditorData = navigator.userAgent
    this.getSettingData()
  },
  methods: {
    saveAgentUrl() {
      this.settingData.userurl = this.userurl
      setSettingConfig(this.settingData).then(() => {
        this.userurl = '';
        this.closeAgentEditor()
      })
    },
    setAgentDefault() {
      this.settingData.userurl = this.userurl = this.settingData.userAgentDefault
      setSettingConfig(this.settingData)
    },
    openAgentEditor() {
      this.userAgentEditorShow = true
      this.userurl = this.settingData.userurl
    },
    closeAgentEditor() {
      this.userAgentEditorShow = false
    },
    getSettingData() {
      getSettingConfig().then((data: any) => {
        console.log(data)
        this.settingData = data
        this.switchValue = Boolean(data.proxy);

        if (!data.userurl) this.setAgentDefault()
      })
    },
    onSave() {
      if (this.switchValue) {
        ;(this.$refs.ipPort as any)
          .validate()
          .then(this.submitSettingData)
          .catch(() => {})
      } else {
        this.submitSettingData()
      }
    },
    submitSettingData() {
      ;(this.$refs.mapKey as any).saveKeyMap()
      const data: any = Object.assign({},this.settingData)
      data.port = Number(data.port) || -1
      setSettingConfig(data).then(() => {
        // (this as any) 主要原因是 vscode会根据声明文件自动进行类型推断的，这里没法知道childMethod的类型
        this.$emit('onSubmit')
      })
    },
    setKeyMapDefault() {
      ;(this.$refs.mapKey as any).saveKeyMapDefault();
    },
    onCancel() {
      this.$emit('onCancel')
    }
  }
})
